import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {
  EbasketApiMethodTypes,
  EbasketInventoryParasparSelectApi,
  EbasketInventorySelectApi,
  EbasketSalesExportSelectApi,
  Providers
} from '@shared/interfaces';
import { SelectApiPopupComponent } from '@shared/select-api-popup/select-api-popup.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'bigCommerce-provider-api-details-settings',
  templateUrl: './bigCommerce-provider-details-settings.component.html',
  styleUrls: ['./bigCommerce-provider-details-settings.component.scss']
})
export class BigCommerceApiDetailsSettingsComponent implements OnInit {
  @Input() action: string;
  @Input() parentForm: FormGroup;
  @Input() ebasketbigCommerceApiDetails: any;
  @Input() authControlKey: string;
  @Input() provider: string;
  @Input() inventory: boolean;
  // @Output() updateApiData: EventEmitter<any> = new EventEmitter();
  apiDetails: FormGroup;
  isEBasketBigcommerceAddDisabled = false;
  ebasket_salesExport_select_api = [];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    console.log('details', this.ebasketbigCommerceApiDetails);
    // select api as stock inquiry in case of inventory section
    if (!this.inventory) {
      this.ebasket_salesExport_select_api = [
        {
          id: 1,
          name: EbasketSalesExportSelectApi.ProcessOrders,
          value: EbasketSalesExportSelectApi.ProcessOrders,
          isDisabled: false
        }
      ];
    } else {
      this.ebasket_salesExport_select_api = [
        {
          id: 1,
          name: EbasketInventorySelectApi.StockEnquiry,
          value: EbasketInventorySelectApi.StockEnquiry,
          isDisabled: false
        }
      ];
    }
    this.initForm();
    this.bindEbasketBigCommerceApiDetailsFormGroup();
  }
  initForm() {
    this.parentForm.addControl(
      this.authControlKey,
      this.formBuilder.group({
        ebasketBigCommerceAccessToken: [
          {
            value:
              this.ebasketbigCommerceApiDetails && this.ebasketbigCommerceApiDetails.apiDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketBigCommerceStoreHash: [
          {
            value:
              this.ebasketbigCommerceApiDetails && this.ebasketbigCommerceApiDetails.apiDetails
                ? Providers.SECRET
                : '',
            disabled: this.action === 'view' ? true : false
          },
          [Validators.required]
        ],
        ebasketBigCommerceApiDetails: this.formBuilder.array([])
      })
    );
  }

  bindEbasketBigCommerceApiDetailsFormGroup() {
    const control = this.parentForm
      .get(this.authControlKey)
      .get('ebasketBigCommerceApiDetails') as FormArray;
    if (this.ebasketbigCommerceApiDetails && this.ebasketbigCommerceApiDetails.apiDetails) {
      this.ebasketbigCommerceApiDetails.apiDetails.map((apis: any) => {
        let apiDetails = {};
        apiDetails = {
          url: [
            {
              value: apis.url,
              disabled: this.action === 'view' ? true : false
            },
            Validators.required
          ],
          selectedApi: [apis.selectedApi]
        };
        control.push(this.formBuilder.group(apiDetails));
        this.ebasket_salesExport_select_api.map((key) => {
          if (key.value === apis.selectedApi) {
            key.isDisabled = true;
          }
        });
      });
      if (this.ebasket_salesExport_select_api.length === control.length) {
        this.isEBasketBigcommerceAddDisabled = true;
      }
    }
  }
  openEbasketBigCommerceSelectApiPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.ebasket_salesExport_select_api.filter((api) => !api.isDisabled);
    const dialogRef = this.dialog.open(SelectApiPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '') {
        this.ebasket_salesExport_select_api.map((element: any) => {
          if (element.value === result) {
            element.isDisabled = true;
            // this.tm_gift_card_api_details.push(element);
            const ebasketBigCommerceApiDetails = this.parentForm
              .get(this.authControlKey)
              .get('ebasketBigCommerceApiDetails') as FormArray;

            ebasketBigCommerceApiDetails.push(this.createInventoryApiDetailsFormGroup(result));
            if (
              this.ebasket_salesExport_select_api.length === ebasketBigCommerceApiDetails.length
            ) {
              this.isEBasketBigcommerceAddDisabled = true;
            }
          }
        });
      }
    });
  }

  private createInventoryApiDetailsFormGroup(selectedApi: string): FormGroup {
    if (this.inventory) {
      return new FormGroup({
        url: new FormControl('', Validators.required),
        selectedApi: new FormControl(selectedApi)
      });
    } else {
      return new FormGroup({
        url: new FormControl('', Validators.required),
        selectedApi: new FormControl(selectedApi)
      });
    }
  }
  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get(this.authControlKey).get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  deleteEbasketInventoryApiDetailContainer(index: number) {
    const ebasketBigCommerceApiDetails = this.parentForm
      .get(this.authControlKey)
      .get('ebasketBigCommerceApiDetails') as FormArray;
    this.ebasket_salesExport_select_api.map((element: any) => {
      if (element.value === ebasketBigCommerceApiDetails.value[index].selectedApi) {
        element.isDisabled = false;
        console.log(this.ebasket_salesExport_select_api);
      }
    });
    if (ebasketBigCommerceApiDetails.length > 0) {
      ebasketBigCommerceApiDetails.removeAt(index);
    } else {
      ebasketBigCommerceApiDetails.reset();
    }
    if (this.ebasket_salesExport_select_api.length !== ebasketBigCommerceApiDetails.length) {
      this.isEBasketBigcommerceAddDisabled = false;
    }
  }
}
