import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators
} from '@angular/forms';
import { ISOTKMgmtSettings, Providers } from '@shared/interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-module-assignment-sotk-settings',
  templateUrl: './sotk-settings.component.html',
  styleUrls: ['./sotk-settings.component.scss']
})
export class SotkSettingsComponent implements OnInit {
  @ViewChild('titleContainer', { static: true }) public titleContainer: any;
  exceptionDataSource = new BehaviorSubject<AbstractControl[]>([]);
  execeptionColumns: any[] = ['buttonName', 'savedSearchName', 'deleteBtn'];
  hostName: any;
  @Input() action: string;
  @Input() moduleIcon: string;
  @Input() parentForm: FormGroup;
  @Input() sotkMgmtSettings: ISOTKMgmtSettings[];
  @Output() deletedRow: EventEmitter<number> = new EventEmitter();
  exceptionArray: FormArray = this.formBuilder.array([]);
  isAddBtnEnabled = false;
  get dc() {
    return this.parentForm;
  }
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.hostName = window.location.origin;
    this.initForm();
  }

  initForm() {
    this.parentForm.addControl(
      'sotkSettings',
      this.formBuilder.group({
        expectedQuantity: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].expectedQuantity || false
            : false,
          { disabled: this.action === 'view' }
        ],
        location: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].location || false
            : false,
          { disabled: this.action === 'view' }
        ],
        mod10: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].mod10 || false
            : false,
          { disabled: this.action === 'view' }
        ],
        mod11: [
          this.sotkMgmtSettings && this.sotkMgmtSettings.length
            ? this.sotkMgmtSettings[0].mod11 || false
            : false,
          { disabled: this.action === 'view' }
        ],
        appName: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        userName: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        password: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        host: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        port: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? Providers.SECRET
            : '',
          { disabled: this.action === 'view' }
        ],
        addReportBtn: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].addReportBtn
            ? this.sotkMgmtSettings[0].addReportBtn || false
            : false,
          { disabled: this.action === 'view' }
        ],
        authenticationDetailsKey: [
          this.sotkMgmtSettings &&
          this.sotkMgmtSettings.length &&
          this.sotkMgmtSettings[0].splunkReportConfiguration &&
          this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            ? this.sotkMgmtSettings[0].splunkReportConfiguration.authenticationDetailsKey
            : ''
        ],
        reportButton: this.formBuilder.array([])
        /*,
        reportButton: [] */
      })
    );
    if (this.action === 'view') {
      this.parentForm.disable();
    } else {
      this.parentForm.enable();
    }
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings.length &&
      this.sotkMgmtSettings[0].addReportBtn
    ) {
      this.isAddBtnEnabled = this.sotkMgmtSettings[0].addReportBtn;
    }

    this.loadReportBtnData();
  }

  loadReportBtnData() {
    if (
      this.sotkMgmtSettings &&
      this.sotkMgmtSettings.length &&
      this.sotkMgmtSettings[0]['reportButton']
    ) {
      const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
      control.clear();
      this.updateExceptionView();
      this.sotkMgmtSettings[0].reportButton.forEach((val) => {
        const row = this.formBuilder.group({
          buttonName: [
            val.buttonName ? val.buttonName : '',
            [Validators.required, Validators.maxLength(30)]
          ],
          savedSearchName: [val.savedSearchName ? val.savedSearchName : '']
        });
        if (this.action === 'view') {
          row.disable();
        } else {
          row.enable();
        }
        control.push(row);
      });
      this.updateExceptionView();
    }
  }

  expectedQuantityChecked() {}

  updateExceptionView() {
    const reportButton = this.parentForm.controls.sotkSettings['controls']
      .reportButton as FormArray;
    this.exceptionDataSource.next(reportButton.controls);
  }

  addReportBtnChecked(values: any): void {
    const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
    if (control && control.controls && control.controls.length) {
      control.controls.forEach((val, index) => {
        control.removeAt(index);
        this.deletedRow.emit(index);
      });
    }
    this.updateExceptionView();

    if (values.checked) {
      this.isAddBtnEnabled = true;
      this.addButtonRow();
    } else {
      this.isAddBtnEnabled = false;
      this.parentForm.controls.sotkSettings['controls'].reportButton.reset();
    }
  }

  addButtonRow() {
    const row = this.formBuilder.group({
      buttonName: ['', [Validators.required, Validators.maxLength(30)]],
      savedSearchName: ['', Validators.required]
    });
    console.log('row : ', row);
    console.log(
      'this.parentForm.controls : ',
      this.parentForm.controls.sotkSettings['controls'].reportButton
    );
    const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
    console.log('control: ', control);
    control.push(row);
    this.updateExceptionView();
  }

  deleteBtnRow(index: number) {
    const control = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
    control.removeAt(index);
    this.updateExceptionView();
    // delete row from formClone as well
    this.deletedRow.emit(index);
    console.log('163 : ', control.controls.length);
    if (control && control.controls && control.controls.length === 0) {
      const row = this.formBuilder.group({
        buttonName: ['', Validators.required, Validators.maxLength(30)],
        savedSearchName: ['', Validators.required]
      });
      const ctrl = this.parentForm.controls.sotkSettings['controls'].reportButton as FormArray;
      console.log('control: ', ctrl);
      ctrl.push(row);
    }
  }

  isRequired(control: string) {
    let isRequired = false;
    const formControlObject = this.parentForm.get('sotkSettings').get(control);
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isRequired = formControlObject.validator({} as AbstractControl).required ? true : false;
        }
      }
    }
    return isRequired ? true : false;
  }
  checkBtnLength(control: string) {
    const formControlObject = this.parentForm.get('sotkSettings').get(control);
    let isValid = true;
    if (formControlObject !== null) {
      if (formControlObject.validator !== null) {
        if (formControlObject.validator({} as AbstractControl) !== null) {
          isValid = formControlObject.validator({} as AbstractControl).maxLength ? true : false;
        }
      }
    }
    return isValid ? true : false;
  }

  getIsAddBtnEnabledValue() {
    return this.parentForm.get('sotkSettings').get('addReportBtn').value;
  }
}
